<template>
  <v-container
    id="dashboard-view"
    style="background-color:#E5E5E5;"
    fluid
    tag="section"
  >
    <back class="my-6" />

    <v-row>
      <v-col
        cols="12"
        md="5"
        lg="4"
        xl="3"
      >
        <v-card
          elevation="0"
          color="transparent"
        >
          <div
            class="font-weight-medium text-subtitle-1 primary--text"
          >
            Gerencia
          </div>
          <div
            class="text-h6 greyTxt--text"
          >
            {{ empleado_data.nombre }} {{ empleado_data.apellido }}
          </div>
          <div
            class="text-subtitle-1 greyTxt--text"
          >
            Área
          </div>
          <v-divider
            class="mb-2 mt-2"
          />

          <v-row class="">
            <v-col
              cols="12"
              md="6"
              class="text-subtitle-2 greyTxt--text"
            >
              <span class="font-weight-negrita mr-2">Legajo</span>
              <span class="font-weight-regular">{{ id }}</span>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="text-subtitle-2 greyTxt--text"
            >
              <span class="font-weight-negrita mr-2">CUIT</span>
              <span class="font-weight-regular">{{ empleado_data.cuit }}</span>
            </v-col>
          </v-row>
          <div class="text-subtitle-2 greyTxt--text my-2">
            <span class="font-weight-negrita mr-2">B. horaria</span>
            <span class="font-weight-regular">{{ empleado_data.b_horaria }}</span>
          </div>
          <div class="text-subtitle-2 greyTxt--text my-2">
            <span class="font-weight-negrita mr-2">Fecha de ingreso</span>
            <span class="font-weight-regular">{{ empleado_data.ingreso }}</span>
          </div>
          <div class="text-subtitle-2 greyTxt--text my-2">
            <span class="font-weight-negrita mr-2">Alta en estado</span>
            <span class="font-weight-regular">{{ empleado_data.alta }}</span>
          </div>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="7"
        lg="8"
        xl="9"
      >
        <v-card
          elevation="2"
          min-height="100%"
        >
          <generic-table
            :loading="loading_empleado"
            :headers="headers_empleado"
            :items="empleado_selected.fichadas"
            height="unset"
            :options="{
              'itemsPerPage':20
            }"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { get, call } from 'vuex-pathify'

  export default {
    name: 'EmpleadoView',

    data () {
      return {

      }
    },

    computed: {
      empleado_selected: get('empleados/empleado_selected'),
      loading_empleado: get('empleados/loading'),
      headers_empleado: get('empleados/empleado_header'),
      id () {
        return this.$route.params?.id
      },
      empleado_data () {
        return this.$route.query
      },
    },
    created () {
      this.initialize()
    },
    methods: {
      getEmpleado: call('empleados/getEmpleado'),
      initialize () {
        this.getEmpleado({ legajo: this.id })
      },
      openDetalle (data) {
        console.log(data)
      },
    },
  }
</script>
